import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import anime from 'animejs/lib/anime.es';
import ReactHtmlParser from 'react-html-parser';
import { withRouter, NavLink } from 'react-router-dom';
import './style.scss';

import checkProps from '../../util/check-props';
import { toggleMenu } from '../../store/modules/menu';
import MenuClose from '../SvgComponents/MenuClose/MenuClose';
import mainNavContent from '../../data/main-nav.json';

var showdown = require('showdown');

class MainMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.ctn = React.createRef();
    this.menuBtn = React.createRef();
    this.contentCtn = React.createRef();
    this.mk = new showdown.Converter();
  }

  componentDidMount() {
    this.menuEls = Array.from(this.ctn.current.querySelectorAll('ul.main-menu li'));
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps) {
    if (prevProps.menuIsOpen !== this.props.menuIsOpen) {
      this.toggleMenu(this.props.menuIsOpen);
      if (this.props.menuIsOpen) {
        this.menuBtn.current.animateToClose();
      } else {
        this.menuBtn.current.animateToMenu();
      }
    }
  }

  toggleMenu(open) {
    if (open) {
      this.contentCtn.current.scrollLeft = 0;
    }
    anime({
      targets: this.menuEls,
      duration: 150,
      delay: anime.stagger(100, { start: open ? 600 : 0 }),
      opacity: {
        value: open ? [0, 1] : [1, 0],
        easing: open ? 'easeInSine' : 'easeOutSine'
      },
      translateX: {
        value: open ? [-40, 0] : [0, -40],
        easing: open ? 'easeOutSine' : 'easeOutQuart'
      }
    });
    anime({
      targets: this.contentCtn.current,
      opacity: open ? [0, 1] : [1, 0],
      duration: open ? 500 : 300,
      delay: open ? 850 : 0,
      easing: open ? 'easeOutSine' : 'easeInSine'
    });
    anime({
      targets: this.ctn.current,
      width: open ? '100vw' : 0,
      duration: 750,
      delay: open ? 0 : 600,
      easing: 'easeInOutExpo'
    });
  }

  showContent(id) {
    const to = this.contentCtn.current.getBoundingClientRect().width * id;
    anime({
      targets: this.contentCtn.current,
      opacity: 0,
      duration: 250,
      easing: 'easeInSine'
    }).finished.then(() =>
      anime({
        targets: this.contentCtn.current,
        scrollLeft: to,
        duration: 0
      }).finished.then(() =>
        anime({
          targets: this.contentCtn.current,
          opacity: 1,
          duration: 250,
          easing: 'easeOutSine'
        })
      )
    );
  }

  render() {
    return (
      <div ref={this.ctn} className="MainMenu bg-white" style={{ height: this.props.viewPortHeight + 'px' }}>
        <div className="main-menu__padding">
          <div className="top-menu d-flex flex-row align-items-start justify-content-between">
            <div className="container">
              <div className="row">
              <div className="brand col-6">
                <NavLink onClick={this.props.toggleMenu} to="/" className="p-relative brand__padding">
                  <img src="/assets/images/godin-logo-dark-blue.png" alt="Logo Godin" />
                </NavLink>
              </div>
              <div className="col-6 buttons text-right d-flex justify-content-end">
                <button
                  className="toggle-menu"
                  onClick={this.props.toggleMenu}
                  onMouseOver={() => this.menuBtn.current.over()}
                  onMouseOut={() => this.menuBtn.current.out()}
                >
                  <MenuClose ref={this.menuBtn} />
                </button>
              </div>
            </div>
            </div>
          </div>
          <div className="menu-inside w-100 d-flex justify-content-center align-items-center" style={{ height: this.props.viewPortHeight + 'px' }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <ul className="main-menu">
                    <li>
                      <NavLink onClick={this.props.toggleMenu} to="/" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={this.props.toggleMenu} to="/about" className="nav-link">
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={this.props.toggleMenu} to="/services" className="nav-link">
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={this.props.toggleMenu} to="/case-studies" className="nav-link">
                        Case studies
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={this.props.toggleMenu} to="/contact" className="nav-link">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-8 content d-none d-md-block" ref={this.contentCtn}>
                  <div className="content-wrapper text-secondary">
                    <div className="mb-4 strong">{mainNavContent.title1}</div>
                    <p>{mainNavContent.description}</p>
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-0">{ReactHtmlParser(this.mk.makeHtml(mainNavContent.address))}</div>
                      </div>
                      <div className="col-6">
                        <a href={`tel:${mainNavContent.phoneNumber}`} className="mb-3">{mainNavContent.phoneNumber}</a><br/>
                        <a href={`mailto:${mainNavContent.email}`}>{mainNavContent.email}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MainMenu.propTypes = checkProps({
  toggleMenu: PropTypes.func,
  viewPortHeight: PropTypes.number,
  menuIsOpen: PropTypes.bool
});

MainMenu.defaultProps = {};

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(toggleMenu())
});

const mapStateToProps = state => {
  return {
    menuIsOpen: state.menu.menuIsOpen,
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainMenu));
